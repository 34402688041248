<template>
  <div>
    <v-card>
      <v-card-title>単品（新規）</v-card-title>

      <template>
        <v-spacer></v-spacer>
        <template v-if="apierror.status == 'error'">
          <div
            v-for="msg of apierror.messages"
            :key="msg"
          >
            <v-row class="ml-6 mb-3 ma-3">
              <span style="color: red">* {{ msg }} </span>
            </v-row>
          </div>
        </template>
        <template>
          <v-divider></v-divider>
          <v-row class="px-2 ma-0 py-0 mt-3 mb-2">
            <v-col cols="12" md="2">
              <label style="font-size:16px;color:#636363">商品コード</label><span style="color: red">*</span>
            </v-col>
            <v-col cols="12" md="7" class="py-0 my-0">
              <v-text-field
                id="item_code"
                v-model="$v.ProductForm.item_code.$model"
                :error-messages="itemCodeErrors"
                color="#fa7a00"
                :counter="13"
                outlined
                dense
                placeholder="JANコード或いは自社コード"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="py-0 my-0">
              <v-btn color="secondary" outlined elevation="0" @click="getItemCode">
                自社コード作成
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <span style="font-size:16px;color:#636363">商品名</span><span style="color:red">*</span>
            </v-col>
            <v-col cols="12" md="7" class="py-0 my-0">
              <v-text-field
                v-model="$v.ProductForm.item_name.$model"
                :error-messages="itemNameErrors"
                color="#fa7a00"
                dense
                :counter="100"
                placeholder="商品名"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <label style="font-size:16px;color:#636363">メーカー名</label>
            </v-col>
            <v-col cols="12" md="7" class="py-0 my-0">
              <v-text-field
                v-model="$v.ProductForm.maker_name.$model"
                :error-messages="itemMakerNameErrors"
                color="#fa7a00"
                outlined
                :counter="100"
                dense
                placeholder="富良野食品サービス"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-card-title style="font-size:16px">
            イメージ
          </v-card-title>
          <v-divider></v-divider>
          <v-row v-if="files.length !== 0" class="ma-0">
            <draggable
              v-model="files"
              class="d-flex flex-row flex-wrap"
              style="height:100%;width:100%"
              animation="300"
            >
              <v-col
                v-for="(item, i) in files"
                :key="i"
                cols="2"
                @contextmenu.prevent="openMenu($event,i)"
              >
                <v-card
                  style="border: 1px solid #CFD8DC"
                  flat
                  tile
                  outlined
                  color="#fafafa"
                  height="100%"
                >
                  <v-img
                    :src="item.full_url"
                    height="120px"
                    contain
                  >
                  </v-img>
                </v-card>
              </v-col>
            </draggable>
          </v-row>
          <div v-else>
            <div class="d-flex justify-center align-center my-4">
              <v-img
                src="@/assets/images/noimage.jpg"
                max-width="120"
              >
              </v-img>
            </div>
            <div class="d-flex justify-center align-center my-4">
              <span>[ + ] ボタンを使用して、さらにイメージを追加します。</span>
            </div>
          </div>
          <v-card-actions class="py-3">
            <AddMedia
              :show-btn="false"
              @exportFile="insertImgs"
            ></AddMedia>
          </v-card-actions>

          <v-row no-gutters class="px-2 ma-3 my-4">
            <v-col
              cols="12"
              md="12"
            >
              <span style="font-size:16px;color:black">商品概要</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-center flex-wrap">
                <v-btn
                  color="#4682B4"
                  elevation="0"
                  class="mb-4 me-3 mt-2"
                  @click="onEditorStatus1"
                >
                  <span style="color:white">編集の表示/非表示</span>
                </v-btn>

                <AddMedia
                  :show-btn="true"
                  @exportFile="insertCkEditor1"
                ></AddMedia>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <ckeditor
                v-if="showEditor1"
                v-model="ProductForm.item_overview"
                :error-messages="overviewErrors"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
              <v-textarea
                v-else
                v-model="ProductForm.item_overview"
                :error-messages="overviewErrors"
                name="note1"
                hide-details="auto"
                outlined
                :counter="5000"
                dense
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row no-gutters class="px-2 ma-3 my-4">
            <v-col
              cols="12"
              md="12"
            >
              <span style="font-size:16px;color:black">商品説明</span>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-center flex-wrap">
                <v-btn
                  color="#4682B4"
                  elevation="0"
                  class="mb-4 me-3 mt-2"
                  @click="onEditorStatus2"
                >
                  <span style="color:white">編集の表示/非表示</span>
                </v-btn>

                <AddMedia
                  :show-btn="true"
                  @exportFile="insertCkEditor2"
                ></AddMedia>
              </div>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <ckeditor
                v-if="showEditor2"
                v-model="ProductForm.item_desc"
                :error-messages="descErrors"
                :editor="editor"
                :config="editorConfig"
              ></ckeditor>
              <v-textarea
                v-else
                v-model="ProductForm.item_desc"
                :error-messages="descErrors"
                name="note2"
                hide-details="auto"
                outlined
                :counter="5000"
                dense
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-divider></v-divider>

          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <label style="font-size:16px;color:#636363">販売価格(税抜き)</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0 my-0">
              <NumberInput
                v-model="ProductForm.item_tep"
                :outlined="true"
                :error-messages="itemTepErrors"
                prefix="¥"
              ></NumberInput>
            </v-col>
          </v-row>
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <label style="font-size:16px;color:#636363">消費税</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0 my-0">
              <v-autocomplete
                v-model="$v.ProductForm.item_ctr.$model"
                :error-messages="itemCtrErrors"
                color="#fa7a00"
                :items="taxs"
                :value="taxs"
                suffix="%"
                outlined
                dense
                placeholder="消費税"
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              md="2"
              class="py-0 my-0"
            >
            </v-col>
            <p class="pb-0 mb-0" style="color:#fa7a00;font-size: small;">
              {{ refValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
            </p>
            <p class="pb-0 mb-0 mr-3" style="font-size: normal;">
              (入力値税込価格)
            </p>
            <p class="pb-0 mb-0" style="color:#fa7a00;font-size: small;">
              {{ taxValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
            </p>
            <p class="pb-0 mb-0" style="font-size: normal;">
              (税金)
            </p>
          </v-row>
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <label style="font-size:16px;color:#636363">販売価格(税込み)</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0 my-0">
              <NumberInput
                v-model="ProductForm.item_tip"
                :outlined="true"
                :error-messages="itemTipErrors"
                prefix="¥"
              ></NumberInput>
            </v-col>
            <v-col
              cols="12"
              md="1"
              class="py-0 my-0"
            >
            </v-col>
            <p v-if="gapValueOn" class="pb-0 mb-0" style="color:#FF0000;font-size: small;">
              {{ gapValue.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') }}
            </p>
            <p v-if="gapValueOn" class="pb-0 mb-0" style="font-size: normal;">
              (計算差額)
            </p>
          </v-row>
          <v-row class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <span style="font-size:16px;color:#636363">内容量</span>
            </v-col>
            <v-col cols="12" md="3" class="py-0 my-0">
              <NumberInput
                v-model="ProductForm.item_weight"
                :outlined="true"
                :error-messages="itemWeightErrors"
                suffix="g"
              ></NumberInput>
            </v-col>
          </v-row>
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <label style="font-size:16px;color:#636363">販売開始日</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0 my-0">
              <v-menu
                v-model="dateStartMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="ProductForm.item_ssd"
                    placeholder="販売開始日"
                    color="#fa7a00"
                    dense
                    hide-details="auto"
                    outlined
                    v-bind="attrs"

                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="ProductForm.item_ssd" color="#fa7a00" @input="dateStartMenu = false"></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <label style="font-size:16px;color:#636363">販売可否</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0 my-0">
              <v-autocomplete
                v-model="ProductForm.saleable"
                :items="sellCan"
                :value="sellCan"
                placeholder="販売可否"
                color="#fa7a00"
                outlined

                dense
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row align="center" class="px-2 ma-0 my-2">
            <v-col cols="12" md="2">
              <label style="font-size:16px;color:#636363">販売終了</label>
            </v-col>
            <v-col cols="12" md="3" class="py-0 my-0">
              <v-autocomplete
                v-model="ProductForm.item_eos"
                :items="sellOver"
                :value="sellOver"
                placeholder="販売終了"
                color="#fa7a00"
                outlined

                dense
                hide-details="auto"
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-card-actions align="center" class="d-flex justify-center mt-5">
            <v-btn
              color="primary"
              class="mr-5"
              :loading="submitStatus"
              @click="submit(ProductForm)"
            >
              <span style="color:white">保存</span>
            </v-btn>
            <v-btn color="secondary" outlined @click="cancelClick">
              <span>キャンセル</span>
            </v-btn>
          </v-card-actions>
        </template>
      </template>
    </v-card>

    <media-edit-menu
      :visible="visible"
      :left="left"
      :top="top"
      @openConfirmDelete="deleteFile"
    ></media-edit-menu>
  </div>
</template>

<script>
import Editor from 'ckeditorplugin'
import {
  mapState, mapActions,
} from 'vuex'
import {
  required, maxLength, numeric,
} from 'vuelidate/lib/validators'

// import {
//   required, maxLength, numeric, minValue,
// } from 'vuelidate/lib/validators'
import AddMedia from '@/components/AddMedia.vue'

import MediaEditMenu from '@/views/bimi/setting/Media/MediaEditMenu.vue'
import NumberInput from '@/components/Input/NumberInput.vue'

export default {
  components: {
    AddMedia,
    MediaEditMenu,
    NumberInput,
  },
  data: () => ({
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    rightClickIndex: '',
    submitStatus: false,
    taxs: [8, 10],
    dateStartMenu: '',
    dateEndMenu: '',
    showEditor1: true,
    visible: false,
    top: 0,
    left: 0,
    editor: Editor,
    editorData: '<p>Content of the editor.</p>',
    editorConfig: {
      // The configuration of the editor.
    },
    files: [],
    images: [],
    editorOption1: {
      /* quill options */
    },
    showEditor2: true,
    editorOption2: {
      /* quill options */
    },
    sellCan: [
      {
        text: '不可', value: 0,
      },
      {
        text: '可', value: 1,
      },
    ],
    sellOver: [
      {
        text: 'YES', value: 1,
      },
      {
        text: 'NO', value: 9,
      },
    ],
    ProductForm: {
      item_code: '',
      item_name: '',
      maker_name: '',
      item_color_code: '',
      images: '',
      item_overview: '',
      item_desc: '',
      item_tep: '0',
      item_ctr: 8,
      item_tip: '0',
      item_weight: '0',
      item_ssd: new Date(Date.now()).toISOString().substring(0, 10),
      item_eos: 1,
      created_at: '',
      created_by: '',
      updated_at: '',
      updated_by: '',
      saleable: 1,
    },
    taxValue: 0,
    refValue: 0,
    gapValue: 0,
    gapValueOn: false,
    changeFlag: false,
  }),
  validations: {
    ProductForm: {
      item_code: {
        required,
        maxLength: maxLength(13),
      },
      item_name: {
        required,
        maxLength: maxLength(100),
      },
      maker_name: {
        maxLength: maxLength(100),
      },
      item_overview: {

        maxLength: maxLength(5000),
      },
      item_desc: {

        maxLength: maxLength(5000),
      },
      item_tep: {
        // required,
        numeric,

        // minValue: minValue(1),
      },
      item_ctr: {
        // required,
        numeric,
      },
      item_tip: {
        // required,
        numeric,

        // minValue: minValue(1),
      },
      item_weight: {
        // required,
        numeric,

        // minValue: minValue(1),
      },
    },
  },
  computed: {
    ...mapState('owncodeStore', ['owncodeList']),

    itemCodeErrors() {
      const errors = []
      if (!this.$v.ProductForm.item_code.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_code.maxLength && errors.push('13文字以内にしてください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_code.required && errors.push('必須項目')

      return errors
    },

    itemNameErrors() {
      const errors = []
      if (!this.$v.ProductForm.item_name.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_name.maxLength && errors.push('100文字以内にしてください。')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_name.required && errors.push('必須項目')

      return errors
    },

    itemMakerNameErrors() {
      const errors = []
      if (!this.$v.ProductForm.maker_name.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.maker_name.maxLength && errors.push('100文字以内にしてください。')

      return errors
    },
    overviewErrors() {
      const errors = []
      if (!this.$v.ProductForm.item_overview.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.ProductForm.item_overview.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_overview.maxLength && errors.push('5000文字以内にしてください。')

      return errors
    },
    descErrors() {
      const errors = []
      if (!this.$v.ProductForm.item_desc.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.ProductForm.item_desc.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_desc.maxLength && errors.push('5000文字以内にしてください。')

      return errors
    },
    itemTepErrors() {
      const errors = []
      if (!this.$v.ProductForm.item_tep.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.ProductForm.item_tep.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_tep.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.ProductForm.item_tep.minValue && errors.push('販売価格(税抜き)は0以外にしてください')

      return errors
    },
    itemCtrErrors() {
      const errors = []
      if (!this.$v.ProductForm.item_ctr.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.ProductForm.item_ctr.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_ctr.numeric && errors.push('必須数字')

      return errors
    },
    itemTipErrors() {
      const errors = []
      if (!this.$v.ProductForm.item_tip.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.ProductForm.item_tip.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_tip.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.ProductForm.item_tip.minValue && errors.push('販売価格(税込み)は0以外にしてください')

      return errors
    },
    itemWeightErrors() {
      const errors = []
      if (!this.$v.ProductForm.item_weight.$dirty) return errors
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.ProductForm.item_weight.required && errors.push('必須項目')
      // eslint-disable-next-line no-unused-expressions
      !this.$v.ProductForm.item_weight.numeric && errors.push('必須数字')
      // eslint-disable-next-line no-unused-expressions
      // !this.$v.ProductForm.item_weight.minValue && errors.push('内容量は0以外にしてください')

      return errors
    },
  },

  watch: {
    visible(value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    },

    ProductForm: {
      handler() {
        this.changeFlag = true
        console.log('changed', this.ProductForm)
      },
      deep: true,
    },

    // 'ProductForm.item_tep': {
    //   handler() {
    //     this.refValue = (this.ProductForm.item_tep * ((100 + this.ProductForm.item_ctr) / 100)).toFixed(2)
    //     this.ProductForm.item_tip = Math.round(this.refValue)
    //     this.taxValue = (this.ProductForm.item_tep * (this.ProductForm.item_ctr / 100)).toFixed(2)
    //   },
    // },

    // 'ProductForm.item_ctr': {
    //   handler() {
    //     this.refValue = (this.ProductForm.item_tep * ((100 + this.ProductForm.item_ctr) / 100)).toFixed(2)
    //     this.taxValue = (this.ProductForm.item_tep * (this.ProductForm.item_ctr / 100)).toFixed(2)
    //     this.ProductForm.item_tip = Math.round(this.refValue)
    //   },
    // },
    // 'ProductForm.item_tip': {
    //   handler() {
    //     this.gapValue = (this.ProductForm.item_tip - this.refValue).toFixed(2)
    //     if (this.gapValue !== 0) {
    //       this.gapValueOn = true
    //     } else {
    //       this.gapValueOn = false
    //     }
    //   },
    // },
    files: {
      handler(newValue) {
        this.ProductForm.images = ''
        newValue.forEach((n, i) => {
          if (i === newValue.length - 1) {
            this.ProductForm.images += `"${n.url}"`
          } else {
            this.ProductForm.images += `"${n.url}",`
          }
        })

        console.log('images', this.ProductForm.images)
      },
    },
  },

  created() {
  },

  methods: {
    ...mapActions('owncodeStore', ['loadOwncodes']),

    cancelClick() {
      if (this.changeFlag) {
        // eslint-disable-next-line no-restricted-globals
        if (confirm('他ページに遷移します。よろしいでしょうか？')) {
          this.jumpToListPage()
        }

        return
      }
      this.jumpToListPage()
    },

    insertImgs(files) {
      // this.files = files
      console.log('files', files)
      if (files.length !== 0) {
        files.forEach(f => {
          this.files.push(f)
        })
        this.ProductForm.imgUrl_prefix = files[0].full_url.replace(files[0].url, '').replace(/\/$/g, '')
      }
    },
    getItemCode() {
      this.loadOwncodes().then(() => {
        console.log('owncodeList', this.itemcodeList)
        this.ProductForm.item_code = this.owncodeList.Owncode
      })
    },

    openMenu(e, index) {
      this.rightClickIndex = index

      const navMenu = document.getElementsByClassName('app-navigation-menu')[0].style.transform
      if (navMenu === 'translateX(0%)') {
        this.left = e.pageX - 268
      } else {
        this.left = e.pageX
      }
      this.top = e.pageY - 74

      this.visible = true
    },

    closeMenu() {
      this.visible = false
    },

    deleteFile() {
      this.files.splice(this.rightClickIndex, 1)
    },

    insertCkEditor1(files) {
      let content = ''
      files.forEach(file => {
        content += `<img src="${file.full_url}" alt="${file.name}" />`
      })
      this.ProductForm.item_overview = content
    },
    insertCkEditor2(files) {
      let content = ''
      files.forEach(file => {
        content += `<img src="${file.full_url}" alt="${file.name}" /><br />`
      })
      this.ProductForm.item_desc = content
    },

    onEditorStatus1() {
      this.showEditor1 = !this.showEditor1
    },
    onEditorStatus2() {
      this.showEditor2 = !this.showEditor2
    },

    getColor(addColor) {
      if (addColor.length !== 0) {
        this.ProductForm.item_color_code = addColor[0].name
      } else {
        this.ProductForm.item_color_code = ''
      }
    },
    jumpToListPage() {
      this.$router.push({
        path: '/items-list',

        // query: this.$route.params.query ?? {
        // },
      })
    },
    submit(ProductForm) {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitStatus = true
        this.createItem(ProductForm)
          .then(() => {
            this.jumpToListPage()
          })
          .catch(error => {
            if (error.response) {
              this.apierror.status = 'error'
              this.apierror.code = error.response.data.code
              this.apierror.messages = error.response.data.message
            }
          })
          .finally(() => {
            this.submitStatus = false
          })
      } else {
        window.scrollTo(0, 0)
        this.apierror.messages = []
        this.apierror.status = 'error'
        this.apierror.messages.push('赤い部分の情報を修正してください')
        console.log('error submit!!')
      }
    },

    // submit(ProductForm) {
    // console.log('ProductForm', ProductForm)
    // if (window.confirm(`販売価格(税込み)\n入力値は ${Math.abs(this.ProductForm.item_tip)} 円, 計算値は ${Math.abs(this.refValue)} 円(差値は ${Math.abs(this.gapValue)} 円)\nよろしいですか?`)) {
    //   this.submitTo(ProductForm)
    // } else {
    //   console.log(ProductForm)
    // }
    // this.submitTo(ProductForm)
    // },

    ...mapActions('itemStore', ['createItem']),
  },
}
</script>
<style>
.ql-editor {
  min-height: 120px;
}
</style>
