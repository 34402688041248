<template>
  <div justify="center">
    <v-btn
      color="primary"
      class="mb-4 me-3"
      @click.stop="openForm"
    >
      <span>フォルダ新規</span>
    </v-btn>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="900px"
    >
      <v-card>
        <v-app-bar color="primary" flat dense>
          <v-icon color="white">
            {{ icons.mdiFolder }}
          </v-icon>
          <span class="pl-3" style="color:white">Create folder</span>
          <v-spacer></v-spacer>
          <v-icon
            color="white"
            @click="dialog = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-app-bar>
        <v-card-actions class="pt-3">
          <v-row>
            <v-col cols="12" md="10" class="pr-0">
              <v-text-field
                v-model="folderName"
                label="Folder name"
                outlined
                hide-details
                clearable
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="1" class="pl-0">
              <v-btn
                depressed
                color="primary"
                @click="create"
              >
                <span>Create</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiClose, mdiFolder,
} from '@mdi/js'
import {
  mapMutations, mapActions,
} from 'vuex'

export default {
  props: ['parentId', 'directory'],
  data: () => ({
    dateMenu: false,
    dialog: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    icons: {
      mdiClose,
      mdiFolder,
    },
    folderName: '',
  }),
  methods: {
    ...mapActions('mediaStore', ['createFolder']),
    ...mapMutations('app', ['setOverlayStatus']),
    create() {
      if (this.folderName !== '') {
        const mediaFolder = {
          folderName: this.folderName, parentId: this.parentId, directory: this.directory,
        }
        this.closeForm()
        this.setOverlayStatus(true)
        this.createFolder(mediaFolder).then(() => {
          this.$emit('reloadMedia')
        })
      }
    },
    openForm() {
      this.dialog = true
    },
    closeForm() {
      this.apierror.messages = []
      this.dialog = false
      this.folderName = ''
    },
  },
}
</script>
