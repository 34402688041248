<template>
  <div justify="center">
    <v-dialog
      v-model="dialog"
      max-width="500"
      persistent
    >
      <v-card tile>
        <v-toolbar
          class="d-flex"
          height="36px"
          color="primary"
          flat
        >
          <v-icon color="#fff">
            {{ icons.mdiRenameBox }}
          </v-icon>
          <span style="color: #fff;flex:1;text-align:left;">名前を変更</span>
          <v-icon
            color="#fff"
            @click="dialog = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-toolbar>
        <v-card-title v-for="item in targetFiles" :key="item.id" class="d-flex">
          <v-icon v-if="item.type === 'folder'">
            {{ icons.mdiFolder }}
          </v-icon>

          <v-icon v-else>
            {{ icons.mdiFileImage }}
          </v-icon>

          <v-text-field
            v-model="item.name"
            class="pl-1"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="me-3"
            @click="renameTarget"
          >
            <span>OK</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="me-3"
            @click="closeForm"
          >
            <span>キャンセル</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiFolder,
  mdiFileImage,
  mdiRenameBox,
} from '@mdi/js'

export default {
  props: ['dialog', 'type', 'targetFiles'],
  data: () => ({
    dateMenu: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    icons: {
      mdiClose,
      mdiFolder,
      mdiFileImage,
      mdiRenameBox,
    },
  }),
  methods: {
    renameTarget() {
      const renameList = [...this.targetFiles]
      this.$emit('renameMedia', renameList)
      console.log('renameMedia', renameList)
      this.closeForm()
    },
    closeForm() {
      this.apierror.messages = []
      this.$emit('closeForm')
      this.folderName = ''
    },
  },
}
</script>

<style scoped>
.v-toolbar--flat >>> .v-toolbar__content {
  width: 100% !important;
}
</style>
