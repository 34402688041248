<template>
  <v-list v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
    <v-list-item
      @click="openRenameDialog"
    >
      <v-list-item-icon>
        <v-icon>{{ icons.mdiPlaylistEdit }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>名前を変更</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      @click="openConfirmDelete"
    >
      <v-list-item-icon>
        <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>削除</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import {
  mdiTrashCanOutline, mdiPlaylistEdit,
} from '@mdi/js'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    left: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    icons: {
      mdiTrashCanOutline,
      mdiPlaylistEdit,
    },
  }),
  methods: {
    openConfirmDelete() {
      this.$emit('openConfirmDelete')
    },
    openRenameDialog() {
      this.$emit('openRenameDialog')
    },
  },
}
</script>

<style scoped>
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
}
</style>
