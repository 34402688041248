<template>
  <div justify="center">
    <v-dialog
      v-model="dialog"
      max-width="500"
      persistent
    >
      <v-card tile>
        <v-toolbar
          class="d-flex"
          height="36px"
          color="primary"
          flat
        >
          <v-icon color="#fff">
            {{ icons.mdiDelete }}
          </v-icon>
          <span style="color: #fff;flex:1;text-align:left;">削除</span>
          <v-icon
            color="#fff"
            @click="dialog = false"
          >
            {{ icons.mdiClose }}
          </v-icon>
        </v-toolbar>
        <v-card-title>
          <span v-if="deleteType === 1">フォルダを削除してもよろしいですか？</span>
          <span v-else-if="deleteType === 2">ファイルを削除してもよろしいですか？</span>
          <span v-else-if="deleteType === 3">ファイルとフォルダを削除してもよろしいですか？</span>
          <span v-else>削除してもよろしいですか？</span>
        </v-card-title>
        <v-card-actions class="pt-3">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="me-3"
            @click="deleteItem"
          >
            <span>OK</span>
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            class="me-3"
            @click="closeForm"
          >
            <span>キャンセル</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiClose,
  mdiFolder,
  mdiDelete,
} from '@mdi/js'

export default {
  props: ['dialog', 'deleteType'],
  data: () => ({
    dateMenu: false,
    apierror: {
      code: '',
      status: '',
      messages: [],
    },
    icons: {
      mdiClose,
      mdiFolder,
      mdiDelete,
    },
    folderName: '',
  }),
  methods: {
    deleteItem() {
      this.$emit('deleteItem')
      this.closeForm()
    },
    closeForm() {
      this.apierror.messages = []
      this.$emit('closeForm')
      this.folderName = ''
    },
  },
}
</script>

<style scoped>
.v-toolbar--flat >>> .v-toolbar__content {
  width: 100% !important;
}
</style>
